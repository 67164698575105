import { faClipboardQuestion, faCouch, faFile, faFileExcel, faFileImage, faFilePdf, faFileVideo, faFileWord, faGraduationCap, faPersonCircleQuestion, faPersonCircleXmark, faPersonRunning, faPhone, faPlaneDeparture, faTruck, faUserTie } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Col from 'react-bootstrap/Col';
import { pdf } from "@react-pdf/renderer"
import { pdfjs } from "react-pdf";
import Cookies from "universal-cookie";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function checkPermission(name){
    const auth = new Cookies().get('auth');
    if(!auth.companyUserIdentifier && auth.adminIdentifier){
        return true;
    }
    const permissions = new Cookies().get('permissions');
    return permissions.includes(name);
}

export function formatPhoneNumber(str) {
    return '(' + str.slice(0, 3) + ') ' + str.slice(3, 6) + '-' + str.slice(6);
}

export function formatSSN(str) {
    return str.slice(0, 3) + '-' + str.slice(3, 5) + '-' + str.slice(5);
}

export function formatTime(time, format) {
    return moment(moment().format('YYYY-MM-DD') + ' ' + time).format(format);
}

export function parseAvailability(str) {
    return str.split('').map(dayBoolean => Boolean(parseInt(dayBoolean)))
}

export function encodeAvailability(availability) {
    return availability.map(dayBoolean => (dayBoolean+0).toString()).join('');
}

export function availabilityToString(availability) {
    const daysOfTheWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    let str = ''

    for (let i = 0; i < 7; i++) {
        if (parseInt(availability[i])) {
            if (str !== '') {
                str += ', ';
            }
            str += daysOfTheWeek[i];
        }
    }
    return str;
}

export function availabilityToStringShort(availability) {
    const daysOfTheWeek = ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
    let str = ''

    for (let i = 0; i < 7; i++) {
        if (availability[i]) {
            if (str !== '') {
                str += ', ';
            }
            str += daysOfTheWeek[i];
        }
    }
    return str;
}

export function convertTime(time){
    const splitTime = time.split(':');
    const hours = splitTime[0];
    const minutes = splitTime[1];
    const pm = hours >= 12;
    const hoursString = hours > 12 ? (hours - 12).toString() : hours == 0 ? '12' : hours.toString();
    return `${hoursString}:${minutes} ${pm ? 'PM' : 'AM'}`;
}

export function intToTime(num) {
    const newNum = num >= 1440 ? (num%24) : num
    const hours = parseInt(newNum / 60);
    const minutesString = (newNum % 60).toString().padStart(2, '0');
    const frontZero = hours < 10 ? '0' : ''

    return `${frontZero}${hours}:${minutesString}:00`;
}

export function stringTimeToInt(str){
    let hourMinuteArray = str.split(':');
    var minutes = (+hourMinuteArray[0]) * 60 + (+hourMinuteArray[1]);
    return minutes;
}

export function availabilityToBinaryString(availability) {
    let str = '';
    ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach((dayOfWeek) => {
        str += (availability.includes(dayOfWeek) ? '1' : '0');
    });
    return str;
}

export function availabilityFromBinaryString(availability) {
    const daysOfTheWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    let str = ''

    for (let i = 0; i < 7; i++) {
        if (availability.toString().charAt(i) == '1') {
            if (str !== '') {
                str += ', ';
            }
            str += daysOfTheWeek[i];
        }
    }
    return str;
}

export function packageAddress(object) {
    object.address = {
        addressIdentifier: object.addressIdentifier,
        thoroughfare: object.thoroughfare, 
        premise: object.premise, 
        locality: object.locality, 
        administrativeArea: object.administrativeArea, 
        postalCode: object.postalCode
    }
}

export function addressComponentsToString(thoroughfare, premise, locality, administrativeArea, postalCode) {
    return addressToString({thoroughfare, premise, locality, administrativeArea, postalCode});
}

export function addressToString(address) {
    if (address) {
        let str = '';

        if (address.thoroughfare) {
            str += address.thoroughfare;
        }
        if (address.premise) {
            if (str.length > 0) {
                str += ' ';
            }
            str += address.premise;
        }
        if (address.locality) {
            if (str.length > 0) {
                str += ', ';
            }
            str += address.locality;
        }
        if (address.administrativeArea) {
            if (str.length > 0) {
                str += ', ';
            }
            str += address.administrativeArea;
        }
        if (address.postalCode) {
            if (str.length > 0) {
                str += ' ';
            }
            str += address.postalCode
        }
        return str;
    } else {
        return '';
    }
}


export const bwcCodeDictionary = {
    '7231': 'Driver',
    '8810': 'Admin', 
    '7219': 'Mechanic', 
}

export const payTypeDictionary = {
    ph: 'Per Hour',
    pd: 'Per Day',
    py: 'Per Year',
    pm: 'Per Mile',
}

export const stateDictionary = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Pennsylvania': 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
};

export const documentDictionary = {
    'W4': 'W4',
    'IT4': 'Ohio State Tax', 
    'Direct Deposit Form': 'Direct Deposit Form', 
    'MDD': 'Multiple Direct Deposit', 
    'WH-4': 'Indiana State Tax',
    'Onboarding Packet': 'Onboarding Packet'
}

export const employeeTypeDictionary = {
    0: 'Full-Time',
    1: 'Part-Time'
}

export const ptoAccrualTypeDictionary = {
    0: 'Per 40 Hours',
    1: 'Per Pay Period'
}

export const timeOffDictionary = {
    '-1': {
        label: 'Rejected Request Off',
        icon: faPersonCircleXmark,
        color: 'lightgray',
    },
    '0': {
        label: 'Pending Request Off',
        icon: faClipboardQuestion,
        color: '#eded77',
    },
    '1': {
        label: 'Day Off',
        icon: faCouch,
        color: '#8affa5'
    }
}

export const titleDictionary = {
    Driver: {
        color: 'lightgray',
    },
    BC: {
        color: 'lightblue',
    },
    AO: {
        color: 'orange'
    },
    Admin: {
        color: 'lime'
    }
}

export function durationToString(durationInMinutes) {

    let totalMinutes = durationInMinutes;
    
    let days = 0;
    let hours = 0;
    let minutes = 0;

    while (totalMinutes >= 1440) {
        days += 1;
        totalMinutes -= 1440;
    }
    while (totalMinutes >= 60) {
        hours += 1;
        totalMinutes -= 60;
    }
    minutes = totalMinutes;
    
    const arr = [];
    if (days) {
        arr.push(days > 1 ? days + ' days' : '1 day');
    }
    if (hours) {
        arr.push(hours > 1 ? hours + ' hours' : '1 hour');
    }
    if (minutes) {
        arr.push(minutes > 1 ? minutes + ' minutes' : '1 minute');
    }

    const str = arr.join(', ');

    return str;
}

export function validateDecimal(value) {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
}

export function validateDecimalFixed(value, decimalPlaces) {
    return parseFloat(validateDecimal(value).toFixed(decimalPlaces));
}

export function validateInteger(value) {
    return isNaN(parseInt(value)) ? 0 : parseInt(value);
}

export function encodeNumber(value, min = null, max = null, defaultValue = 0) {
    const number = parseFloat(value);
    if (isNaN(number)) {
        return defaultValue;
    } else if (min != null && number < min) {
        return min;
    } else if (max != null && number > max) {
        return max;
    } else {
        return number;
    }
}

export function encodeInteger(value, min = null, max = null, defaultValue = 0) {
    const number = parseInt(value);
    if (isNaN(number)) {
        return defaultValue;
    } else if (min != null && number < min) {
        return min;
    } else if (max != null && number > max) {
        return max;
    } else {
        return number;
    }
}

export function encodeBig(value, lhDecimalPlaces, rhDecimalPlaces, defaultValue = 0) {
    return encodeDecimal(value.toString(), lhDecimalPlaces, rhDecimalPlaces, defaultValue = 0);
}


export function encodeDecimal(value, lhDecimalPlaces, rhDecimalPlaces, forcePositive, defaultValue = 0) {
    const number = parseFloat(value);
    if (isNaN(number)) {
        return defaultValue;
    } else {
        const maxNumber = Math.pow(10, lhDecimalPlaces) - 1;
        const numberWithMax = Math.min(number, maxNumber);
        const numberWithForcePositive = forcePositive ? Math.max(0, numberWithMax) : numberWithMax;
        return numberWithForcePositive.toFixed(rhDecimalPlaces); 
    }
}

export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getStartOfWeek() {
    return moment().day() === 6 ? moment() : moment().startOf('week').subtract(1, 'days');
}

export function wrapElementInCol(element, breakpoints) {
    return (
        <Col style={{marginBottom: 8}} xs={breakpoints.xs} sm={breakpoints.sm} md={breakpoints.md} lg={breakpoints.lg} xl={breakpoints.xl} xxl={breakpoints.xxl}>
            {element}
        </Col>
    )
}

export function dateRangeToString(startDate, endDate) {
    if (!startDate && !endDate) {
        return 'All Time';
    } else if (!startDate) {
        return 'Before ' + moment(endDate).format('MMM D, YYYY');
    } else if (!endDate) {
        return 'After ' + moment(startDate).format('MMM D, YYYY');
    } else {
        return moment(startDate).format('MMM D, YYYY') + ' - ' + moment(endDate).format('MMM D, YYYY');
    }
}

export function dateIsInRange(date, startDate, endDate) {
    if (!startDate && !endDate) {
        return true;
    } else if (!startDate) {
        return moment(endDate).diff(moment(date), 'days') >= 0;
    } else if (!endDate) {
        return moment(date).diff(moment(startDate), 'days') >= 0;
    } else {
        return moment(date).isBetween(moment(startDate), moment(endDate), 'days', '[]');
    }
}

export function formatPastDateTime(date) {
    if (moment().isSame(moment(date), 'year')) {
        if (moment().isSame(moment(date), 'week')) {
            if (moment().isSame(moment(date), 'day')) {
                return moment(date).format('h:mm A');
            } else {
                return moment(date).format('ddd D');
            }
        } else {
            return moment(date).format('MMM D')
        }
    } else {
        return moment(date).format('MMM D, YYYY')
    }
}

export function formatDateRange(startDate, endDate) {
    if (!startDate && !endDate) {
        return 'All Time';
    } else if (!startDate) {
        return 'Before ' + moment(endDate).format('MMM D, YYYY');
    } else if (!endDate) {
        return 'After ' + moment(startDate).format('MMM D, YYYY');
    } else {
        if (moment(startDate).isSame(moment(endDate), 'year')) {
            if (moment(startDate).isSame(moment(endDate), 'month')) {
                if (moment(startDate).isSame(moment(endDate), 'day')) {
                    return moment(startDate).format('MMM D, YYYY');
                } else {
                    return `${moment(startDate).format('MMM D')}-${moment(endDate).format('D, YYYY')}`;
                }
            } else {
                return `${moment(startDate).format('MMM D')} - ${moment(endDate).format('MMM D, YYYY')}`;
            }
        } else {
            return `${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}`;
        }
    }
}

export function getFileIcon(documentType) {
    switch (documentType) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'heic':
        case 'heic':
            return faFileImage;
        case 'pdf':
            return faFilePdf;
        case 'doc':
        case 'docx':
        case 'odt':
        case 'rtf':
        case 'txt':
        case 'tex':
        case 'wpd':
            return faFileWord;
        case 'xlsx':
        case 'xls':
        case 'xlsm':
        case 'csv':
        case 'tsv':
        case 'xml':
            return faFileExcel;
        case 'mp4':
        case 'avi':
        case 'wmv':
        case 'mov':
        case 'flv':
        case 'avchd':
        case 'mpeg':
            return faFileVideo;
        default:
            return faFile;
    }
}

export function getNameMatches(user, search){
    const partialName = `${user.firstName}${user.lastName}`;
    const partialNameReversed = `${user.lastName}${user.firstName}`;
    const fullName = `${user.firstName}${user.middleName}${user.lastName}`;
    const fullNameAsDisplayed = `${user.lastName}${user.firstName}${user.middleName}`;
    return (
            user.firstName.toLocaleLowerCase().includes(search.toLocaleLowerCase()) 
            || user.lastName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            || partialName.toLocaleLowerCase().includes(search.replaceAll(' ','').replaceAll(',','').toLocaleLowerCase()))
            || partialNameReversed.toLocaleLowerCase().includes(search.replaceAll(' ','').replaceAll(',','').toLocaleLowerCase())
            || fullName.toLocaleLowerCase().includes(search.replaceAll(' ','').replaceAll(',','').toLocaleLowerCase()
            || fullNameAsDisplayed.toLocaleLowerCase().includes(search.replaceAll(' ','').replaceAll(',','').toLocaleLowerCase())
    )
}

export function sortTimes(a, b) {
    const aNum = parseInt(a.slice(0, 2) + a.slice(3, 5));
    const bNum = parseInt(b.slice(0, 2) + b.slice(3, 5));
    return aNum < bNum ? -1 : aNum > bNum ? 1 : 0;
}

export function getTimeOnDayAsMoment(time, date) {
    return moment(moment(date).format('YYYY-MM-DD') + ' ' + time);
}


let fakeUID = 0;

export function getFakeUID() {
    fakeUID--;
    return fakeUID;
}



//ED Document Stuff \/\/\/\/\/\/


export const defaultOfferLetterTitles = {
    'Driver': 'defaultOfferLetterForDriver',
    'BC': 'defaultOfferLetterForBc',
    'Admin': 'defaultOfferLetterForAdmin'
}

export const OnboardingDocuments = {
    'w4': 'W-4 (Employee’s Withholding Certificate)',
    'i9': 'I-9 (Employment Eligibility Verification)',
    'f8850': 'Form 8850 (for Work Opportunity Credit)',
}

export const DirectDepositDocuments = {
    'dd': 'Direct Deposit Authorization',
    'mdd': 'Multiple Direct Deposit Authorization',
}

export const taxDocumentDictionary = {
    'a4': 'A4 (Alabama)',
    'wh4': 'WH-4 (Indiana)',
    'k4': 'K-4 (Kentucky)',
    'l4': 'L-4 (Louisiana)',
    'miw4': 'MI-W4 (Michigan)',
    'it2104': 'IT-2104 (New York)',
    'it4': 'IT-4 (Ohio)',
    'rev419': 'REV-419 (Pennsylvania)',
    'va4': 'VA-4 (Virginia)'
}

export const OnboardingDocumentsShort = {
    w4: 'W-4',
    i9: 'I-9',
    f8850: 'Form 8850',
}

export const TaxDocumentsShort = {
    a4: 'A4',
    wh4: 'WH-4',
    k4: 'K-4',
    l4: 'L-4',
    miw4: 'MI-W4',
    it2104: 'IT-2104',
    it4: 'IT-4',
    rev419: 'REV-419',
    va4: 'VA-4',
}

export const OnboardingDocumentsReadOnly = {
    'archivedDocument': 'Archived Document',
    'offerLetter': 'Offer Letter', 
    ...OnboardingDocuments,
    ...taxDocumentDictionary,
    ...DirectDepositDocuments
}

export const stateTaxDocuments = {
    'AL': 'a4',
    'IN': 'wh4',
    'KY': 'k4',
    'LA': 'l4',
    'MI': 'miw4',
    'NY': 'it2104',
    'OH': 'it4',
    'PA': 'rev419',
    'VA': 'va4'
}

export const defaultOfferLetter = `[CompanyName]
[CreatedDate]
re: Employment offer
[EmployeeName]

Dear [EmployeeName],

[CompanyName] is pleased to offer you employment as a [EmployeeType] Linehaul delivery driver.

Your start date will be [StartDate]. You will report to your immediate supervisor [SupervisorName] at [TerminalAddress] for training by [StartTime] on the date listed above.

Your starting pay will be [Pay].

You are expected to be available [ExpectedAvailability], and your start time for each scheduled work day will be [DailyStartTime].

You will receive an Employee Handbook with all of the [CompanyName] employment policies. It is your responsibility to read the entire Employee Handbook, understand all the policies in it, and abide by these policies while employed at [CompanyName]. Failure to follow company policies will lead to progressive disciplinary action, up to and including termination. You will be required to acknowledge the Employee Handbook when the Handbook is given to you.

You may qualify for the following benefits: [Benefits]

Note that [CompanyName] is an At-Will employer and you are an At-Will employee, therefore [CompanyName] or you may end your employment with [CompanyName] at any time for any reason. This employment letter does not constitute a contract for employment but is merely an informational document for you to use as a new employee of [CompanyName].

Sincerely,
[AOName]
   
Authorized Officer | [AOPhoneNumber] | [AOEmail]`;

export function parseOfferLetter(content, data){
    content = content.replaceAll('[CompanyName]', data.companyName);
    content = content.replaceAll('[CreatedDate]', moment().format('MMM D, YYYY'));
    content = content.replaceAll('[EmployeeName]', data.employeeName);
    content = content.replaceAll('[EmployeeType]', employeeTypeDictionary[data.employeeType]);
    if (!!data.startDate) {
        content = content.replaceAll('[StartDate]', moment(data.startDate).format('MMM D, YYYY'));
    }
    if (!!data.supervisorName) {
    content = content.replaceAll('[SupervisorName]', data.supervisorName);
    }
    content = content.replaceAll('[TerminalAddress]', addressToString(data.terminalAddress));
    if (!!data.startTime) {
    content = content.replaceAll('[StartTime]', formatTimeNew(data.startTime));
    }
    content = content.replaceAll('[Pay]', `${usdFormatter.format(data.payRate)} ${payTypeDictionary[data.payType]}`);
    content = content.replaceAll('[StopBonusThresholds]', data.stopBonusWeekdayThreshold == data.stopBonusWeekendThreshold ? `${validateInteger(data.stopBonusWeekdayThreshold)} stops` : `${validateInteger(data.stopBonusWeekdayThreshold)}/${validateInteger(data.stopBonusWeekendThreshold)} (weekdays/weekends) stops`);
    content = content.replaceAll('[StopBonusAmounts]', data.stopBonusWeekdayAmount == data.stopBonusWeekendAmount ? usdFormatter.format(data.stopBonusWeekdayAmount) : `${usdFormatter.format(data.stopBonusWeekdayAmount)}/${usdFormatter.format(data.stopBonusWeekendAmount)} (weekdays/weekends)`);
    content = content.replaceAll('[ExpectedAvailability]', availabilityFromBinaryString(data.expectedAvailability));
    if (!!data.dailyStartTime) {
    content = content.replaceAll('[DailyStartTime]', formatTimeNew(data.dailyStartTime));
    }
    content = content.replaceAll('[Benefits]', data.benefits ?? 'There are currently no available benefits for this employer');
    if(!!data.aoName){
        content = content.replaceAll('[AOName]', data.aoName);
    }
    if (!!data.aoPhoneNumber) {
    content = content.replaceAll('[AOPhoneNumber]', formatPhoneNumber(data.aoPhoneNumber));
    }
    if(!!data.aoEmail){
        content = content.replaceAll('[AOEmail]', data.aoEmail);
    }
    return content;
}


export async function downloadBase64(base64, title) {
    const response = await fetch(base64);
    const blob = await response.blob();
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = title;        
    document.body.appendChild(elem);
    elem.click();        
    document.body.removeChild(elem);
}

export async function reactPdfToBase64(doc) {
    const blob = await pdf(doc).toBlob();
    const thing = await new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        }
        reader.readAsDataURL(blob); 
    })
    return thing;
}

export async function base64PdfToBase64Images(base64) {
    const pdf = await pdfjs.getDocument(base64).promise;

    const pages = [];
    for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({scale: 1.5});
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        canvas.height = viewport.height; /* viewport.height is NaN */
        canvas.width = viewport.width;  /* viewport.width is also NaN */
        await page.render({canvasContext, viewport}).promise;
        pages.push(canvas.toDataURL());
    };
    return pages;
}

export async function downloadReactPdf(doc, title = '') {
    const blob = await pdf(doc).toBlob();
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.target = '_blank';
    elem.download = title;        
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
}

export function formatTimeNew(str) {
    if (!str) {
        return '';
    }
    const hours = parseInt(str.substring(0, 2));
    const minutes = parseInt(str.substring(3));
    const pm = hours > 12;
    const addLeadingZero = minutes < 10;

    return `${pm ? hours - 12 : hours}:${addLeadingZero ? '0' : ''}${minutes} ${pm ? 'PM' : 'AM'}`;
}

export function formatEin(ein) {
    return ein.toString().slice(0, 2) + '-' + ein.toString().slice(2);
}

export function naturalSort (array, type){
    var a, b, a1, b1, rx=/(\d+)|(\D+)/g, rd=/\d+/;
    return array.sort(function(as, bs){
        a= String(as[type]).toLowerCase().match(rx);
        b= String(bs[type]).toLowerCase().match(rx);
        while(a.length && b.length){
            a1= a.shift();
            b1= b.shift();
            if(rd.test(a1) || rd.test(b1)){
                if(!rd.test(a1)) return 1;
                if(!rd.test(b1)) return -1;
                if(a1!= b1) return a1-b1;
            }
            else if(a1!= b1) return a1> b1? 1: -1;
        }
        return a.length- b.length;
    });
}


export function toFixedMax(number, decimalPlaces) {
    return parseFloat(parseFloat(number).toFixed(decimalPlaces));
}