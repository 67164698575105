/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Document, Page, Text, View } from "@react-pdf/renderer";
import { parseOfferLetter } from "../../../../tools";

export default function OfferLetterPdf({content, offerLetterData}) {
    return (
        <Document>
            <Page size='LETTER' style={{padding: 50, position: 'relative'}}>
                <Text style={{fontSize: 11.5}}>{parseOfferLetter(content, offerLetterData)}</Text>

                <Text style={{fontSize: 11.5, position: 'absolute', bottom: 120, left: 50}}>Please sign and date to confirm receipt of this document.</Text>
                <View style={{position: 'absolute', left: 50, bottom: 50, display: 'flex', flexDirection: 'row', gap: 50, fontSize: 11.5}}>
                    <Text style={{borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4}}>Employee's Signature</Text>
                    <Text style={{borderTop: '1px solid black', paddingLeft: 12, paddingRight: 50, paddingTop: 4}}>Date</Text>
                </View>
            </Page>
        </Document>
    )
}