/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, Modal, Row } from 'react-bootstrap';
import { ApiRequest } from '../../../../ApiManager.tsx';
import { DirectDepositDocuments, OnboardingDocuments, OnboardingDocumentsShort, TaxDocumentsShort, defaultOfferLetter, defaultOfferLetterTitles, parseOfferLetter, stateTaxDocuments, taxDocumentDictionary, validateDecimalFixed } from '../../../../tools.js';
import LoadingWrapper from '../../../../components/LoadingWrapper.js';
import CustomCheck from '../../../../components/CustomCheck.js';
import SwitchControl from '../../../../components/SwitchControl.js';
import RadioControl from '../../../../components/RadioControl.js';
import OfferLetterEditor from '../../../../components/OfferLetterEditor.js';
import CustomButton from '../../../../components/CustomButton.js';
import { useStateObjectWithValidation } from '../../../../hooks.tsx';
import { Validation } from '../../../../validation.tsx';
import CustomControl from '../../../../components/CustomControl.js';
import moment from 'moment';

export default function DocumentRequestModal({companyUserIdentifier, selectedCompany, hideModal, defaultAllDocumentsOn}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [signers, setSigners] = useState([]);
    const [employee, setEmployee] = useState(undefined);
    const [company, setCompany] = useState(undefined);
    const [terminal, setTerminal] = useState(undefined);
    const [showOfferLetter, setShowOfferLetter] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState(defaultAllDocumentsOn ? ['w4', 'i9', 'dd', 'f8850'] : []);
    const [selectedDirectDepositDocument, setSelectedDirectDepositDocument] = useState(defaultAllDocumentsOn ? 'dd' : null);
    const [selectedTaxDocument, setSelectedTaxDocument] = useState(undefined);
    const [includeEContacts, setIncludeEContacts] = useState(defaultAllDocumentsOn ?? false);
    const [includeOfferLetter, setIncludeOfferLetter] = useState(defaultAllDocumentsOn ?? false);
    const [offerLetters, setOfferLetters] = useState([]);
    const [offerLetter, setOfferLetter] = useState(defaultOfferLetter);
    const [selectedOfferLetter, setSelectedOfferLetter] = useState(-1);
    const requiresEmployerSigner = selectedDocuments.includes('i9') || selectedDocuments.includes('f8850') || ['a4', 'l4', 'miw4', 'it2104', 'rev419'].includes(selectedTaxDocument);
    const requiresStartDate = selectedDocuments.includes('w4') || selectedDocuments.includes('i9') || includeOfferLetter;
    const [requiredInformation, setRequiredInformation, setRequiredInformationKey, requiredInformationVMs, requiredInformationIsValid] = useStateObjectWithValidation({
        startDate: '',
        supervisorName: '',
        startTime: '08:00',
        dailyStartTime: '08:00',
        selectedAo: null,
        employerSigner: null,
    }, 
    {
        startDate: requiresStartDate ?  Validation.date : Validation.none,
        supervisorName: includeOfferLetter ? Validation.nonEmptyString : Validation.none,
        startTime: includeOfferLetter ? Validation.nonEmptyString : Validation.none,
        dailyStartTime: includeOfferLetter ? Validation.nonEmptyString : Validation.none,
        selectedAo:  (requiresEmployerSigner || requiresStartDate || includeOfferLetter) ? Validation.notUndefined : Validation.none,
        employerSigner: requiresEmployerSigner ? Validation.notUndefined : Validation.none,
    })
    const offerLetterElements = offerLetters.map((ol) => {
        return (
            <Dropdown.Item key={ol.uid} onClick={() => {setOfferLetter(ol.content); setSelectedOfferLetter(ol.uid)}}>{ol.name}</Dropdown.Item>
        )
    })

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('hr', 'getDocumentRequestData', setIsLoading, (response) => {
            setEmployee(response.employee);
            setCompany(response.company);
            setTerminal(response.terminal);
            setSigners(response.signerCandidates);
            response.offerLetters.forEach(ol=>{
                const associatedDefaults = [];
                if(response.company.defaultOfferLetterForDriver == ol.uid)
                    associatedDefaults.push('Driver')
                if(response.company.defaultOfferLetterForAvpDriver == ol.uid)
                    associatedDefaults.push('BC')
                if(response.company.defaultOfferLetterForAdmin == ol.uid)
                    associatedDefaults.push('Admin')
                ol.associatedDefaults = associatedDefaults;
            })
            setOfferLetters(response.offerLetters);
            setRequiredInformationKey('startDate', response.employee.startDate ? response.employee.startDate : '');
            if(response.company[defaultOfferLetterTitles[response.employee.pay.title]]) {
                setOfferLetter(response.offerLetters.find(ol=>ol.uid == response.company[defaultOfferLetterTitles[response.employee.pay.title]]).content) 
                setSelectedOfferLetter(response.offerLetters.find(ol=>ol.uid == response.company[defaultOfferLetterTitles[response.employee.pay.title]]).uid)
            }
            if (defaultAllDocumentsOn) {
                setSelectedTaxDocument(stateTaxDocuments[response.terminal?.address.administrativeArea] ?? undefined);
            }
        }).withData({companyUserIdentifier: companyUserIdentifier, companyIdentifier: selectedCompany}).withNoAlertOnSuccess().send()
    }

    function handleSubmit(){
        const documents = {};
        selectedDocuments.forEach((type) => {
            const document = {title: `${employee.firstName} ${employee.lastName} ${OnboardingDocumentsShort[type]}`};
            if (type == 'w4') {
                document.startDate = requiredInformation.startDate;
            }
            documents[type] = document;
        })
        if (selectedTaxDocument) {
            documents[selectedTaxDocument] = {title: `${employee.firstName} ${employee.lastName} ${TaxDocumentsShort[selectedTaxDocument]}`};
        }
        if (selectedDirectDepositDocument) {
            documents[selectedDirectDepositDocument] = {title: `${employee.firstName} ${employee.lastName} ${DirectDepositDocuments[selectedDirectDepositDocument]}`};
        }
        if (includeOfferLetter) {
            const ao = signers.find(s => s.uid ==  requiredInformation.selectedAo);
            const offerLetterData = {
                title: `${employee.firstName} ${employee.lastName} Offer Letter`,
                content: offerLetter,
                companyName: company.name,
                employeeName: `${employee.firstName} ${employee.lastName}`,
                employeeType: employee.pay.employeeType,
                startDate:  requiredInformation.startDate,
                supervisorName:  requiredInformation.supervisorName,
                terminalAddress: terminal.address,
                startTime: moment(requiredInformation.startTime, 'HH:mm').format('HH:mm:ss'),
                payType: employee.pay.payType,
                payRate: validateDecimalFixed(employee.pay.payRate, 2),
                expectedAvailability: employee.availability,
                dailyStartTime: moment(requiredInformation.dailyStartTime, 'HH:mm').format('HH:mm:ss'),
                benefits: company.benefits,
                aoName: `${ao.firstName} ${ao.lastName}`,
                aoPhoneNumber: ao.phoneNumber,
                aoEmail: ao.email
            };
            documents.offerLetter = {
                content: parseOfferLetter(offerLetter, offerLetterData),
                data: offerLetterData
            }
        }

        new ApiRequest('hr', 'createDocumentRequest', setIsSubmitting, () => hideModal()).withData({
            companyUserIdentifier: companyUserIdentifier,
            companyIdentifier: selectedCompany,
            documents: documents,
            employerEmail:   requiredInformation.employerSigner ?? '',
            includeEContacts: includeEContacts,
            startDate: requiredInformation.startDate ? requiredInformation.startDate : null
        }).send()
    }

    const offerLetterAos = signers.filter(s => s.title === 'AO');
    const totalDocumentsSelected = selectedDocuments.length + (selectedTaxDocument ? 1 : 0) + (includeEContacts ? 1 : 0) + (includeOfferLetter ? 1 : 0) + (selectedDirectDepositDocument ? 1 : 0);
    const offerLetterRadioValues = [-1, ...offerLetters.map(ol => ol.uid)];
    const offerLetterRadioLabels = ['TTA Default', ...offerLetters.map(ol => `${ol.name}${ol.associatedDefaults.length ? ` (${ol.associatedDefaults.join(', ')})` : ''}`)];
    
    if (selectedOfferLetter == null) {
        offerLetterRadioValues.push(null);
        offerLetterRadioLabels.push('Custom');
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Send Employee Document Request</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <LoadingWrapper isLoading={isLoading}>
                    <div style={{border: '1px solid lightgray', padding: 12, borderRadius: 6}}>
                        <h5 style={{textAlign: 'center'}}>Onboarding Documents to Include</h5>
                        <Container fluid style={{padding: 0}}>
                            <Row>
                                <Col xl={6} style={{display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 8}}>
                                    <CustomCheck inline title='Documents' selection={selectedDocuments} setSelection={setSelectedDocuments} optionNames={Object.values(OnboardingDocuments)} optionValues={Object.keys(OnboardingDocuments)}/>                                    
                                    <SwitchControl title={'Include Direct Deposit Form'} value={selectedDirectDepositDocument !== null} setValue={(value) => {setSelectedDirectDepositDocument(value ? 'dd' : null)}}/>
                                    { selectedDirectDepositDocument && 
                                        <RadioControl title='Selected Direct Deposit Form' inline selection={selectedDirectDepositDocument} setSelection={setSelectedDirectDepositDocument} optionNames={Object.values(DirectDepositDocuments)} optionValues={Object.keys(DirectDepositDocuments)}/>
                                    }
                                    <SwitchControl title={'Request Emergency Contacts'} value={includeEContacts} setValue={setIncludeEContacts}/>
                                </Col>
                                <Col xl={6} style={{display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 8}}>
                                    <SwitchControl title={'Include State Tax Document'} value={selectedTaxDocument !== undefined} setValue={(value) => {setSelectedTaxDocument(value ? stateTaxDocuments[terminal?.address.administrativeArea] ?? 'it4' : undefined)}}/>
                                    { selectedTaxDocument && 
                                        <RadioControl inline title={'Selected State Tax Document'} selection={selectedTaxDocument} setSelection={setSelectedTaxDocument} optionNames={Object.values(taxDocumentDictionary)} optionValues={Object.keys(taxDocumentDictionary)}/>
                                    }
                                    <SwitchControl title={'Include Offer Letter'} value={includeOfferLetter} setValue={setIncludeOfferLetter}/>
                                    { includeOfferLetter && 
                                        <>
                                            <RadioControl 
                                                inline 
                                                title={'Selected Offer Letter'} 
                                                selection={selectedOfferLetter} 
                                                setSelection={(value)=>{setOfferLetter(offerLetters.find(ol=>ol.uid == value)?.content ?? defaultOfferLetter); setSelectedOfferLetter(value)}} 
                                                optionNames={offerLetterRadioLabels} 
                                                optionValues={offerLetterRadioValues}
                                            />
                                            <Button variant='outline-primary' onClick={() => {setShowOfferLetter(true)}}>
                                                <span>Edit Offer Letter</span>
                                            </Button>
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    { (requiresEmployerSigner || requiresStartDate || includeOfferLetter) && 
                        <div style={{border: '1px solid lightgray', padding: 12, borderRadius: 6}}>
                            <h5 style={{textAlign: 'center'}}>Required Information</h5>
                            <Container fluid style={{padding: 0}}>
                                <Row>
                                    { requiresEmployerSigner && 
                                        <RadioControl breakpoints={{lg: 12}} validate validationMessage={requiredInformationVMs.employerSigner} inline title={'Signer on behalf of Employer'} selection={requiredInformation.employerSigner} setSelection={(value) => setRequiredInformationKey('employerSigner', value)} optionNames={signers.map(c => `${c.firstName} ${c.lastName} (${c.title})`)} optionValues={signers.map(c => c.email)}/>
                                    }
                                    <RadioControl breakpoints={{lg: 12}} title='AO to Appear on Offer Letter' validate validationMessage={requiredInformationVMs.selectedAo} selection={requiredInformation.selectedAo} setSelection={(value) => setRequiredInformationKey('selectedAo', value)} optionValues={offerLetterAos.map(ao => ao.uid)} optionNames={offerLetterAos.map(ao => `${ao.firstName} ${ao.lastName}`)} inline/>
                                    { requiresStartDate && 
                                        <CustomControl breakpoints={{lg: 6}} title='Employment Start Date' validate validationMessage={requiredInformationVMs.startDate} value={requiredInformation.startDate} setValue={(value) => setRequiredInformationKey('startDate', value)} type='date'/>
                                    }
                                    { includeOfferLetter && 
                                        <>
                                            <CustomControl breakpoints={{lg: 6}} title='Name of Immediate Supervisor' validate validationMessage={requiredInformationVMs.supervisorName} value={requiredInformation.supervisorName} setValue={(value) => setRequiredInformationKey('supervisorName', value)}/>
                                            <CustomControl breakpoints={{lg: 6}} title='Start Time on First Day' validate validationMessage={requiredInformationVMs.startTime} value={requiredInformation.startTime} setValue={(value) => setRequiredInformationKey('startTime', value)} type='time'/>
                                            <CustomControl breakpoints={{lg: 6}} title='Daily Start Time' validate validationMessage={requiredInformationVMs.dailyStartTime} value={requiredInformation.dailyStartTime} setValue={(value) => setRequiredInformationKey('dailyStartTime', value)} type='time'/>
                                        </>
                                    }
                                </Row>
                            </Container>
                        </div>
                    }
               </LoadingWrapper>
            </Modal.Body>
            <Modal.Footer>
                <span style={{color: totalDocumentsSelected ? 'green' : 'red'}}>{`${totalDocumentsSelected} document${totalDocumentsSelected != 1 ? 's' : ''} selected`}</span>
                <CustomButton isLoading={isSubmitting} onClick={handleSubmit} disabled={totalDocumentsSelected == 0 || (includeOfferLetter && !offerLetter) || !requiredInformationIsValid}>Submit Document Request</CustomButton>
            </Modal.Footer>
            <Modal show={showOfferLetter} onHide={() => {setShowOfferLetter(false)}} fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title>Offer Letter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Dropdown.Toggle variant='outline-primary'>Import Template</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {offerLetterElements}
                        </Dropdown.Menu>
                    </Dropdown>
                    <h5 style={{textAlign: 'center', color: 'gray', fontStyle: 'italic', margin: 0}}>Any modifications will be reflected in the offer letter sent to the employee</h5>
                    <OfferLetterEditor value={offerLetter} setValue={setOfferLetter} setSelectedOfferLetter={setSelectedOfferLetter} offerLetterData={{
                        companyName: company?.name,
                        employeeName: `${employee?.firstName} ${employee?.lastName}`,
                        employeeType: employee?.pay.employeeType,
                        startDate: requiredInformation.startDate,
                        supervisorName: requiredInformation.supervisorName,
                        terminalAddress: terminal?.address,
                        startTime: requiredInformation.startTime,
                        payType: employee?.pay.payType,
                        payRate: validateDecimalFixed(employee?.pay.payRate, 2),
                        expectedAvailability: employee?.availability ?? '',
                        dailyStartTime: requiredInformation.dailyStartTime,
                        benefits: company?.benefits,
                        aoName: signers.find(s => s.uid == requiredInformation.selectedAo) ? `${signers.find(s => s.uid == requiredInformation.selectedAo).firstName} ${signers.find(s => s.uid == requiredInformation.selectedAo).lastName }` : undefined,
                        aoPhoneNumber: signers.find(s => s.uid == requiredInformation.selectedAo) ? signers.find(s => s.uid == requiredInformation.selectedAo)?.phoneNumber : undefined,
                        aoEmail: signers.find(s => s.uid == requiredInformation.selectedAo) ? signers.find(s => s.uid == requiredInformation.selectedAo)?.email : undefined,
                    }}/>    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-primary' onClick={() => {setShowOfferLetter(false)}}>Done</Button>
                </Modal.Footer>
            </Modal>
            
        </>
    )
}